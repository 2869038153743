import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import resourcesData from '../components/resources.json';

interface Resource {
  name: string;
  url: string;
  description: string;
}

type ResourceCategory = 'General' | 'Science' | 'English' | 'Maths' | 'Economics' | 'YouTube Channels';

const Resources: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<ResourceCategory | 'All'>('All');
  const [searchQuery, setSearchQuery] = useState('');

  const categories: (ResourceCategory | 'All')[] = ['All', 'General', 'Science', 'English', 'Maths', 'Economics', 'YouTube Channels'];

  const resources: Record<ResourceCategory, Resource[]> = resourcesData;

  const filteredResources = (selectedCategory === 'All' 
    ? Object.values(resources).flat() 
    : resources[selectedCategory] || []
  ).filter(resource => 
    resource.name.toLowerCase().includes(searchQuery.toLowerCase()) || 
    resource.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div
      className="relative leading-normal tracking-normal text-indigo-400 m-0 bg-cover bg-fixed"
      style={{
        backgroundImage: "url('/main.webp')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
      }}
    >
      <div className="absolute inset-0 bg-white bg-opacity-30 backdrop-blur-sm z-0"></div>

      <div className="relative z-10 h-full flex flex-col">
        <Navbar />

        <div className="container pt-24 md:pt-36 mx-auto px-4 lg:px-8">
          <div className="bg-gray-900 bg-opacity-75 p-4 rounded-lg shadow-lg mb-8">
            <input
              type="text"
              placeholder="Search resources..."
              className="input input-bordered w-full mb-4"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="flex flex-wrap gap-2">
              {categories.map((category) => (
                <button
                  key={category}
                  onClick={() => setSelectedCategory(category as ResourceCategory | 'All')}
                  className={`btn btn-sm ${selectedCategory === category ? 'btn-primary bg-indigo-500 hover:bg-indigo-700 text-white' : 'btn-outline bg-gray-600 hover:bg-gray-800 text-white'}`}
                >
                  {category}
                </button>
              ))}
            </div>
          </div>

          <div>
            {filteredResources.length === 0 ? (
              <p className="text-white">No resources found.</p>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                {filteredResources.map((resource) => (
                  <div key={resource.url} className="card bg-gray-900 bg-opacity-75 shadow-md hover:shadow-lg compact transition-shadow duration-200">
                    <div className="card-body p-3">
                      <h2 className="card-title text-indigo-300 text-sm">{resource.name}</h2>
                      <p className="text-gray-400 text-xs mb-2">{resource.description}</p>
                      <div className="card-actions justify-end">
                        <a 
                          href={resource.url} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="btn btn-sm btn-outline text-indigo-300 border-indigo-300 hover:bg-indigo-500 hover:text-white transition-colors"
                        >
                          Visit
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="mt-8 bg-gray-900 bg-opacity-75 p-4 rounded-lg shadow-lg text-center text-white">
            <p>
              If you would like to suggest or add new resources, please contact Sage, Cyber, or any member of the dev team on our Discord server.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
