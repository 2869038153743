import { Link } from 'react-router-dom';

const Navbar: React.FC = () => {
  return (
    <nav className="w-full container mx-auto py-4">
      <div className="w-full flex items-center justify-between">
        {/* Logo / Home Link */}
        <Link
          className="flex items-center text-indigo-400 no-underline hover:no-underline font-bold text-2xl lg:text-4xl ml-6"
          to="/"
        >
          NCEA
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-green-400 via-pink-500 to-purple-500">
            Help
          </span>
        </Link>

        {/* Navigation Links */}
        <div className="flex w-1/2 justify-end content-center space-x-4 pr-6">
          <Link
            className="inline-block text-blue-300 no-underline hover:text-pink-500 hover:text-underline text-center h-10 p-2 md:h-auto md:p-4 transform hover:scale-125 duration-300 ease-in-out"
            to="/resources"
          >
            Resources
          </Link>

          <Link
            className="inline-block text-blue-300 no-underline hover:text-pink-500 hover:text-underline text-center h-10 p-2 md:h-auto md:p-4 transform hover:scale-125 duration-300 ease-in-out"
            to="/faq"
          >
            FAQ
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
