import React from 'react';
import Navbar from '../components/Navbar';
import {Link} from 'react-router-dom';

const FAQPage : React.FC = () => {
    return (
        <div
            className="relative leading-normal tracking-normal text-indigo-400 m-0 bg-cover bg-fixed"
            style={{
            backgroundImage: "url('/main.webp')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: '100vh'
        }}>
            <div className="absolute inset-0 bg-white bg-opacity-30 backdrop-blur-sm z-0"></div>

            <div className="relative z-10 h-full flex flex-col">
                <Navbar/>

                <div className="container pt-24 md:pt-36 mx-auto px-4 lg:px-8">
                    <div className="bg-gray-900 bg-opacity-75 p-6 rounded-lg shadow-lg mb-8">
                        <h1 className="text-3xl font-bold text-indigo-300 mb-4">FAQ</h1>
                        <div className="space-y-4">
                            <div tabIndex={0} className="collapse bg-gray-800 text-gray-200">
                                <div className="collapse-title text-xl font-medium">
                                    How does NCEA work?
                                </div>
                                <div className="collapse-content">
                                    <p>
                                        NCEA is based on a mix of internal assessments (like assignments or projects)
                                        and external exams. Each subject has various standards worth a certain number of
                                        credits, and you need a minimum number of credits to pass each level.
                                    </p>
                                </div>
                            </div>
                            
                            <div tabIndex={0} className="collapse bg-gray-800 text-gray-200">
                                <div className="collapse-title text-xl font-medium">
                                    What are 'endorsements' in NCEA?
                                </div>
                                <div className="collapse-content">
                                    <p>
                                        You can earn Merit or Excellence Endorsements for both individual subjects and
                                        your overall NCEA. To get an Excellence Endorsement in a subject, you need 14
                                        credits at Excellence level; for Merit Endorsement, 14 credits at Merit (or
                                        higher). For an overall certificate endorsement, you need at least 50 credits at
                                        Merit or Excellence across all your subjects.
                                    </p>
                                </div>
                            </div>

                            <div tabIndex={0} className="collapse bg-gray-800 text-gray-200">
                                <div className="collapse-title text-xl font-medium">
                                    How can I get help with NCEA?
                                </div>
                                <div className="collapse-content">
                                    <p>
                                        Super easy! Just join our Discord server and head over to the subject-specific
                                        channels (like Maths, Science, English, etc.). If you're stuck, just drop a
                                        question in the chat! For more one-on-one help, you can create a private ticket
                                        by reacting in the TUTOR SUPPORT category, and one of our Student Helpers will
                                        assist you.
                                    </p>
                                </div>
                            </div>

                            <div tabIndex={0} className="collapse bg-gray-800 text-gray-200">
                                <div className="collapse-title text-xl font-medium">
                                    What happens if I fail a standard?
                                </div>
                                <div className="collapse-content">
                                    <p>
                                        Failing a standard doesn’t mean you fail the whole subject or NCEA. You just
                                        won’t earn credits for that particular standard, but you can still pass if you
                                        have enough credits from other standards. You can also sometimes re-sit internal
                                        standards or exams, depending on your school’s policies.
                                    </p>
                                </div>
                            </div>

                            <div tabIndex={0} className="collapse bg-gray-800 text-gray-200">
                                <div className="collapse-title text-xl font-medium">
                                    How can I get University Entrance (UE)?
                                </div>
                                <div className="collapse-content">
                                    <p>
                                        To get University Entrance, you need to achieve: - NCEA Level 3, - 14 credits in
                                        three approved subjects, - 10 literacy credits at Level 2 or above, and - 10
                                        numeracy credits at Level 1 or above.
                                    </p>
                                </div>
                            </div>

                            <div tabIndex={0} className="collapse bg-gray-800 text-gray-200">
                                <div className="collapse-title text-xl font-medium">
                                    What if I need help with assignments?
                                </div>
                                <div className="collapse-content">
                                    <p>
                                        If you're stressing over an assignment, hop into the any of the subject channels
                                        and ask for advice. You can also open a ticket if you need a bit more in-depth
                                        support. Make sure to give us details like what subject and topic you're
                                        struggling with!
                                    </p>
                                </div>
                            </div>


                            <div tabIndex={0} className="collapse bg-gray-800 text-gray-200">
                                <div className="collapse-title text-xl font-medium">
                                    Where can I find study resources?
                                </div>
                                <div className="collapse-content">
                                    <p>
                                        We've got you covered! Check out the resources channel or
                                        <Link to="/resources">
                                            our resources page</Link>
                                        for NCEA past papers, study guides, and helpful links shared by other students.
                                        If you're looking for something specific, feel free to ask in the Discord!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQPage;
