import Navbar from '../components/Navbar';

function Home() {
  return (
      <div
        className="relative leading-normal tracking-normal text-indigo-400 m-0 bg-cover bg-fixed"
        style={{
          backgroundImage: "url('/main.webp')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh',
        }}
      >
        <div className="absolute inset-0 bg-white bg-opacity-30 backdrop-blur-sm z-0"></div>

        <div className="relative z-10 h-full flex flex-col">
          <Navbar />
          
          <div className="container pt-24 md:pt-36 mx-auto flex flex-wrap flex-col md:flex-row items-center px-4 lg:px-8">
            <div className="flex flex-col w-full xl:w-2/5 justify-center lg:items-start overflow-y-hidden">
              <h1 className="my-4 text-3xl md:text-5xl text-white opacity-75 font-bold leading-tight text-center md:text-left">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-green-400 via-pink-500 to-purple-500">
                  NCEA Help
                </span>
              </h1>
              <p className="leading-normal text-base md:text-2xl mb-8 text-center md:text-left">
                Your Path to NCEA Excellence
              </p>

              <div className="bg-gray-900 opacity-75 w-full shadow-lg rounded-lg px-8 pt-6 pb-8 mb-4">
                <p className="text-blue-300 py-2 font-bold mb-4">
                  Join Our Discord Community!
                </p>
                <p className="text-gray-300 mb-6">
                  Connect with students, receive 1-on-1 help, and excel in your studies—join NCEA Help today and take charge of your learning journey!
                </p>

                <div className="flex items-center justify-between pt-4">
                  <a
                    href="https://discord.gg/ncea-help-842980715355176980"  
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-gradient-to-r from-purple-800 to-green-500 hover:from-pink-500 hover:to-green-500 text-white font-bold py-2 px-4 rounded focus:ring transform transition hover:scale-105 duration-300 ease-in-out text-center"
                  >
                    Join Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Home;
